<!-- 
    This component is responsible of displaying the dataset export options and triggering the appropriate exporting method
    - prop - gridFilters - filters that are currently applied to the dataset
    - emit - makeExcel - need to export excel
    - emit - makeCsv - need to export csv
 -->
<template>
    <b-container fluid>
        <b-row class="mt-3">
            <b-col>
                <span>Select the file type to export the information to.</span>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col sm="12">
                <div
                    class="export-item-wrapper flex-row-wrapper content-placement-center"
                    v-for="item in exportTypes"
                    :key="item.name"
                    @click="showExport(item.name)"
                >
                    <div
                        class="export-item flex-row-wrapper content-placement-center"
                        :class="{ activeItem: activeExportItem === item.name }"
                    >
                        <img :src="item.img" font-scale="1.5" class="mx-3" />{{
                            item.name
                        }}
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div
                    class="export-item-wrapper flex-row-wrapper content-placement-end"
                >
                    <b-button
                        rounded
                        @click="exportItem"
                        class="buttonSelect"
                        :disabled="activeExportItem === ''"
                    >
                        Export
                        <img src="../assets/icon_down_circle.svg" class="pl-2"
                    /></b-button>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapMutations } from 'vuex';
import { datasetService } from '../services/dataset.service';
import { commonService } from '../services/common.service';
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
export default {
    components: {},
    props: {
        dataSetId: Number,
        filter: String,
        filterColName: String,
        projectIds: Array,
        fileName: String,
        gridType: {
            type: String,
            required: false,
            default: '',
        },
        pdfExport: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            activeExportItem: '',
            exportTypes: [],
            queueTaskId: 1805,
            queueStatusInterval: null,
            queueService: 'Doc Manager',
        };
    },
    created() {
        this.exportTypes = [
            { name: 'CSV', img: require('../assets/icon_csv.svg') },
            { name: 'XLSX', img: require('../assets/icon_xlsx.svg') },
            { name: 'ACCDB', img: require('../assets/icon_accdb.svg') },
        ];
        if (this.pdfExport) {
            this.exportTypes.push({
                name: 'PDF Report',
                img: require('../assets/icon_pdf.svg'),
            });
        }
    },
    mounted() {
        //this.checkQueueStatus();
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        showExport(item) {
            this.activeExportItem = item;
        },
        async getDocManagerExport() {
            try {
                const response = await datasetService.createDocManagerRequest(
                    '4', //template grouping
                    this.projectIds[0],
                    this.gridType
                );
                const jobId = response.data.Data;

                await this.checkStatusUntilComplete(jobId);
            } catch (err) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: 'failed!',
                    visible: true,
                    downloadLink: '',
                });
            }
        },
        async exportItem() {
            if (this.activeExportItem === 'CSV') {
                this.$emit('exportData', 'CSV');
            } else if (this.activeExportItem === 'XLSX') {
                this.$emit('exportData', 'XLSX');
            } else if (this.activeExportItem === 'ACCDB') {
                datasetService
                    .exportToAccessDB(
                        JSON.parse(this.gridFilteroptions),
                        this.dataSetId,
                        this.projectIds
                    )
                    .then((res) => {
                        // download zip file
                        var blob = commonService.b64ToBlob(
                            res.data,
                            'application/zip'
                        );
                        const href = URL.createObjectURL(blob);
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `${this.fileName}.zip`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        if (res.data.StatusCode !== 500) {
                            this.setToastMessage({
                                showSuccess: true,
                                successToastMessage:
                                    'File exported successfully.',
                            });
                        }
                    })
                    .catch((error) => {
                        this.setToastMessage({
                            showFailure: true,
                            failureToastMessage: 'No data to export!',
                        });
                    });
            } else if (this.activeExportItem === 'PDF Report') {
                await this.getDocManagerExport();
            }
        },
        async checkStatusUntilComplete(jobId) {
            let continueLoop = true;

            try {
                while (continueLoop) {
                    const statusResponse = await datasetService.getStatus(
                        jobId
                    );
                    const status = statusResponse.data;
                    switch (status) {
                        case 'Completed':
                            var response = await datasetService.getQueue(jobId);
                            var functionReturnStatus = JSON.parse(
                                response.data.FunctionReturnStatus
                            );
                            var docId = +functionReturnStatus.Data;

                            var docHref = await this.downloadFile(docId);
                            var fileName = await this.getFileName(docId);
                            var link = await commonService.getDownloadLink(
                                docHref,
                                fileName
                            );

                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'complete',
                                queueMessage: 'completed!',
                                downloadLink:
                                    '<a href="' +
                                    link +
                                    '" download="' +
                                    fileName +
                                    '" > Download ' +
                                    fileName +
                                    ' </a>',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                        case 'Error':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                        case 'Unkown JobID':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                        case 'Queued':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'queue',
                                queueMessage: 'in queue!',
                                visible: true,
                                downloadLink: '',
                            });
                            break;
                        case 'Processing':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                visible: true,
                                downloadLink: '',
                            });
                            break;
                        default:
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: status,
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 10000));
                }
            } catch (error) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: error,
                    visible: true,
                    downloadLink: '',
                });
            }
        },
        async downloadFile(docId) {
            var response = await commonService.getDocumentLinkByID(docId);
            return response;
        },
        async getFileName(docId) {
            var response = await commonService.getDocument(docId);
            return response.data.FileName;
        },
    },
    computed: {
        ...mapState({
            secondLevelFilter: (state) => state.gridFilters.secondLevelFilter,
            gridFilteroptions: (state) => state.gridFilters.gridFilter,
        }),
    },
};
</script>

<style scoped>
.flex-row-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.content-placement-center {
    justify-content: center;
}
.content-placement-end {
    justify-content: end;
}
.export-item-wrapper {
    width: 100%;
    height: 100px;
}

.export-item {
    border: 1px solid #c6c6c6;
    width: 70%;
    height: 90%;
}
.export-item:hover {
    border: 1px solid #000;
    background-color: #eaeaea;
}
.activeItem {
    border: 2px solid #1b1b1b;
    background-color: #eaeaea;
}
</style>
